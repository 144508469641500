/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* #root{
  height: 100%;
  width: 100%;
} */

#screen{
  width: 100%;
  height: 100vh;
  position: relative;
}

#main{
  width: 90%;
  height: 98vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  /* text-align: center; */
}

#QuestionText{
  height: 5vh;
  font-size: 2.5vh;
  text-align: center;
}
#chartTitle{
  height: 5vh;
  font-size: 2.5vh;
  text-align: center;
  color:rgb(64, 64, 64) ;
}

.emojiBtn{
  max-height: 20vh;
  opacity: 1.0;
  background-color: transparent;
  /* border: 3px groove #c58729; */
}
.emojiBtn:hover{
  opacity: 0.8;
}
.emojiBtn:active{
  opacity: 0.6;
}

#chart{
  display: block;
}

#btnTable{
  width: 100%;
  height: 20vh;
  text-align: center;
  padding-bottom: 1vh;
  border-bottom: solid 3px rgba(111, 111, 111, 0.1);
}


#chartDiv{
  /* width: 100%; */
  height: 60vh;
}


#none{
  width: 0;
}

#selectEmoji{
  width: 35%;
  padding-top: 5%;
  padding-bottom: 5%;
}

#bar{
  width: 100%;
  margin-top: 2vh;
  margin-bottom: 1vh;
  color: rgb(230, 230, 230);
  /* height: 7vh; */
}

#modalBtn{
  text-align: center;
  width: 100%;
}

#pieChartDiv{
  width: 100%;
  height: 100%;
  display: flex;
}

#test1{
  min-width: 50%;
  height: 100%;
  padding-left: 25%;
}

#test2{
  min-width: 10%;
  height: fit-content;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 3%;
}

/* #timeList{
  position: absolute;
  height: fit-content;
  top: 50%;
  left: 80%;
  transform: translate(-50%, -50%);
  /* border: solid 1px black;
}*/

/* .list{
  text-align: center;
} */

/* #calenderDiv, 
#calenderDiv > div.react-staticdatepicker-wrapper, 
#calenderDiv > div > div.react-staticdatepicker__input-container
#calenderDiv > div > div.react-staticdatepicker__input-container input {
  width: 200%;
  height: 200%;
} */

/* #emoji1{
  background-color: rgb(232, 51, 19);
}
#emoji2{
  background-color: rgb(232, 143, 19);
}
#emoji3{
  background-color: rgb(225, 232, 19);
}
#emoji4{
  background-color: rgb(19, 232, 40);
}
#emoji5{
  background-color: rgb(35, 194, 252);
}
#emoji6{
  background-color: rgb(57, 35, 252);
} */